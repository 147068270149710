import request from './request'
/**
 * 企业动态
 */
export function information_page(data) {
  return request({
    url: '/web/information/v1/information_page',
    method: 'post',
    data
  })
}
/**
 * 媒体报道
 */
 export function information_info(data) {
  return request({
    url: '/web/information/v1/information_info',
    method: 'post',
    data
  })
}
