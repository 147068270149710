<template>
  <div class="media">
    <!-- <img class="media-bg" src="../../static/images/media-bg.png" alt="" /> -->
        <div class="header">
      <div class="header-img">
        <div style="display: flex;" @click="jumpToHome">
          <div>
            <img style="width:0.75rem;height:0.75rem;object-fit:cover;" src="../../static/images/home-b-t.png" alt="">
          </div>
          <div style="margin-top: 0.1rem;">
            <img style="width:1.47rem;height:0.49rem;object-fit:cover;vertical-align: super;margin-left: 0.1rem;" src="../../static/images/home-b-a.png" alt="">
          </div>
        </div>
        <div class="right" @click="jumpToHome1">
          <img style="width:0.68rem;height:0.57rem;object-fit:cover;" src="../../static/images/home-s-b.png" alt="">
        </div>
      </div>
      <!-- <div class="header-text">ABOUT US</div> -->
    </div>
    <div class="media-content">
      <!-- 企业动态 -->
      <div class="dynamic">
        <div class="dynamic-title">企业动态</div>
        <div class="dynamic-subtitle">ENTERPRISE DYNAMICS</div>
        <div class="dynamic-aier">
          <span class="dynamic-dynamic">AIR BRIDGE</span>
          <span class="dynamic-chinese">艾尔伴家</span>
        </div>
        <div>
          <div
            class="img-top"
            v-for="(item, index) in projectList"
            :key="index"
            @click="jumpToRated"
          >
            <div class="rated-left">
              <img :src="item.coverUrlName" alt="" />
            </div>
            <div class="rated-right">
              <div class="rated-right-title">
                <div class="rated-right-circular"></div>
                <div class="rated-right-text">获 评</div>
              </div>
              <div class="rated-right-content">{{ item.createTime }}</div>
              <p class="rated-right-content">
                {{ item.infoTitle }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- 行业动态 -->
      <div class="dynamic">
        <div class="dynamic-title">行业动态</div>
        <div class="dynamic-subtitle">INDUSTRY DYNAMICS</div>
        <div class="dynamic-aier">
          <span class="dynamic-dynamic">AIR BRIDGE</span>
          <span class="dynamic-chinese">艾尔伴家</span>
        </div>
        <div>
          <div
            class="img-top"
            v-for="(item, index) in industryList"
            :key="index"
            @click="jumpToSafety"
          >
            <div class="rated-left">
              <img :src="item.coverUrlName" alt="" />
            </div>
            <div class="rated-right">
              <div class="rated-right-title">
                <div class="rated-right-circular"></div>
                <div class="rated-right-text">平 安 智 慧 城</div>
              </div>
              <div class="rated-right-content">{{ item.createTime }}</div>
              <p class="rated-right-content">
                {{ item.infoTitle }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- 媒体报道 -->
      <div class="media">
        <div class="dynamic-title">媒体报道</div>
        <div class="dynamic-subtitle">MEDIA COVERAGE</div>
        <div class="dynamic-aier">
          <span class="dynamic-dynamic">AIR BRIDGE</span>
          <span class="dynamic-chinese">艾尔伴家</span>
        </div>
        <div>
          <div>
            <div class="media-title">媒体报道</div>
            <div class="media-report">
              <video
                autoplay
                loop
                :src="videoData.videoUrlName"
                controls
                style="width: 100%; height: 100%"
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div class="media-title">今日报道</div>
            <div class="media-report">
              <video
                autoplay
                loop
                :src="today.videoUrlName"
                controls
                style="width: 100%; height: 100%"
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div class="media-title">智能门禁</div>
            <div class="media-report">
              <video
                autoplay
                loop
                :src="access.videoUrlName"
                controls
                style="width: 100%; height: 100%"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { information_page, information_info } from "@/api/media"
import dayJs from "dayjs"
export default {
  name: "Media",
  data() {
    return {
      videoData: {},
      today: {},
      access: {},
      /** 企业动态列表 */
      projectList: [],
      /** 行业动态列表 */
      industryList: [],
      /** 媒体报道列表 */
      mediaList: []
    }
  },
  created() {
    this.getPage(),
      this.getInformation_info(),
      this.industryPage(),
      this.mediaPage()
  },
  methods: {
          jumpToHome() {
        this.$router.push('/')
      },
      jumpToHome1() {
        this.$router.push('/home2')
      },
    getPage() {
      information_page({
        infoType: "info_project",
        pageNum: 1,
        pageSize: 20,
        total: 0
      }).then((res) => {
        if (res.code === 0) {
          res.data.list.map((item) => {
            item.createTime = dayJs(item.createTime).format("YYYY-MM-DD")
            return item
          })
          this.projectList = res.data.list
        }
      })
    },
    industryPage() {
      information_page({
        infoType: "info_industry",
        pageNum: 1,
        pageSize: 20,
        total: 0
      }).then((res) => {
        if (res.code === 0) {
          res.data.list.map((item) => {
            item.createTime = dayJs(item.createTime).format("YYYY-MM-DD")
            return item
          })
          this.industryList = res.data.list
        }
      })
    },
    mediaPage() {
      information_page({
        infoType: "info_media",
        pageNum: 1,
        pageSize: 20,
        total: 0
      }).then((res) => {
        if (res.code === 0) {
          res.data.list.map((item) => {
            item.createTime = dayJs(item.createTime).format("YYYY-MM-DD")
            return item
          })
          this.mediaList = res.data.list
        }
      })
    },
    getInformation_info() {
      information_info({ id: 12 }).then((res) => {
        if (res.code === 0) {
          this.videoData = res.data
        }
      })
      information_info({ id: 14 }).then((res) => {
        if (res.code === 0) {
          this.today = res.data
        }
      })
      information_info({ id: 13 }).then((res) => {
        if (res.code === 0) {
          this.access = res.data
        }
      })
    },
    jumpToRated() {
      this.$router.push("/rated")
    },
    jumpToReturnees() {
      this.$router.push("/returnees")
    },
    jumpToSafety() {
      this.$router.push("/safety")
    }
  }
}
</script>

<style scoped>
.header{
  position: relative;
  padding: 0.26rem 0.4rem 0 0.4rem;
  box-sizing: border-box;
  width: 100%;
  height: 6.05rem;
  background: url('../../static/images/media-bg.png');
  background-size: 100% 100%;
}
.header-img{
  display: flex;
  justify-content: space-between;
}
.header-text{
  width: 4.6rem;
  position: absolute;
  margin-left: -1.6rem;
  top: 52%;
  left: 50%;
  font-size: 0.56rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #FFFFFF;
  letter-spacing: 3px;
}
.media-bg {
  width: 100%;
  height: 6.09rem;
  margin-bottom: 1.17rem;
}
.media-content {
  margin-top: 1.14rem;
  padding: 0 0.4rem;
  box-sizing: border-box;
}
.dynamic-title {
  width: 2.56rem;
  height: 0.53rem;
  font-size: 0.56rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #27201b;
}
.dynamic-subtitle {
  width: 6.77rem;
  height: 0.39rem;
  font-size: 0.48rem;
  font-family: Source Han Sans CN;
  font-weight: 300;
  color: #666666;
  margin-top: 0.2rem;
  margin-bottom: 0.16rem;
}
.dynamic-aier {
  margin-bottom: 1rem;
}
.dynamic-dynamic {
  padding: 0 0.05rem;
  margin-right: 0.1rem;
  background: #ffab0a;
  width: 1.65rem;
  height: 0.29rem;
  text-align: center;
  line-height: 0.29rem;
  font-size: 0.24rem;
  font-family: Source Han Sans CN;
  font-weight: 300;
  color: #fffefe;
}
.dynamic-chinese {
  width: 1.25rem;
  height: 0.31rem;
  font-size: 0.32rem;
  font-family: Source Han Sans CN;
  font-weight: 300;
  color: #ffab0a;
}
.img-top {
  margin-bottom: 1.1rem;
  display: flex;
  align-items: center;
}
.rated-left {
  width: 5.29rem;
  height: 3.31rem;
  box-shadow: 0.04rem 0.04rem 0.04rem 0.04rem #d4d3d2;
}
.rated-left img {
  width: 100%;
  height: 100%;
}
.rated-right {
  padding: 0.36rem 0.1rem 0.27rem 0.2rem;
  flex: 1;
  height: 2.95rem;
  background: #e5e5e5;
  box-sizing: border-box;
  margin-left: 0.086rem;
}
.rated-right-title {
  display: flex;
  display: flex;
  line-height: 0.2rem;
  margin-top: 0.2rem;
}
.rated-right-circular {
  width: 0.12rem;
  height: 0.12rem;
  background: #ffab0a;
  border-radius: 50%;
  line-height: 0.43rem;
}
.rated-right-text {
  margin-left: 0.2rem;
  height: 0.43rem;
  font-size: 0.45rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffab0a;
}
.rated-right-content {
  font-size: 0.35rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  line-height: 0.64rem;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.media-title {
  font-size: 0.37rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  margin-bottom: 0.17rem;
}
.media-report {
  width: 100%;
  height: 5.17rem;
  margin-bottom: 1.5rem;
}
</style>
